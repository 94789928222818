const controlSpecialTabs = (tabContainer, tab, e) => {
    if (e.target.closest('.stb__tab').classList.contains('tab-is-active')) {
        return;
    }

    const activeTabs = Array.from(tabContainer.querySelectorAll('.tab-is-active'));
    activeTabs.forEach(activeTab => {
        activeTab.classList.remove('tab-is-active');
    });

    const tabId = tab.dataset.tabId;
    tab.closest('.stb__tab').classList.add('tab-is-active');
    const activeContent = tabContainer.querySelector(`[data-special-tab-id=${tabId}]`);
    activeContent.classList.add('tab-is-active');
}

const handleSpecialTab = (tabContainer) => {
    const allTabs = Array.from(tabContainer.querySelectorAll('.stb__tab--toggle'));

    allTabs.forEach(tab => {
        tab.addEventListener("click", (e) => controlSpecialTabs(tabContainer, tab, e))
    })
}

export { handleSpecialTab };