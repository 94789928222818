const changeTab = (e, tab, container) => {
    if (tab.classList.contains('active-tab') && window.innerWidth > 767) {
        return;
    } else if (tab.classList.contains('mobile-active-tab') && window.innerWidth < 767) {
        const currentActiveTab = container.querySelector('.active-tab');
        const currentActiveInfo = container.querySelector('.active-info');
        currentActiveTab.classList.remove('active-tab');
        currentActiveInfo.classList.remove('active-info');
        currentActiveTab.classList.remove('mobile-active-tab');
        currentActiveInfo.classList.remove('mobile-active-info');
        return;
    }

    const currentActiveTab = container.querySelector('.active-tab');
    const currentActiveInfo = container.querySelector('.active-info');

    if (currentActiveTab && currentActiveInfo) {
        currentActiveTab.classList.remove('active-tab');
        currentActiveInfo.classList.remove('active-info');
        currentActiveTab.classList.remove('mobile-active-tab');
        currentActiveInfo.classList.remove('mobile-active-info');
    }


    const tabTopic = tab.dataset.tab;
    const parentContainer = tab.closest('.tac__tab--container');
    const infoContainer = parentContainer.querySelector(`.tac__tab--info.tab-${tabTopic}`);
    tab.classList.add('active-tab');
    tab.classList.add('mobile-active-tab');
    infoContainer.classList.add('active-info');
    infoContainer.classList.add('mobile-active-info');
}

export const handleTabChange = (container) => {
    const allTabs = Array.from(container.querySelectorAll('.tac__tab'));

    allTabs.forEach(tab => {
        tab.addEventListener('click', (e) => changeTab(e, tab, container));
    })
}