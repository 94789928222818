const escapeHtml = (str) => {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    return String(str).replace(/[&<>"'`=\/]/g, (s) => entityMap[s]);
}

export const handleNameSearch = (e, nameForm) => {
    e.preventDefault();
    const searchPage = nameForm.dataset.link;
    const searchValue = nameForm.querySelector('.tac__tab--form-input').value;
    const escapedValue = escapeHtml(searchValue);

    if (escapedValue) {
        window.location.assign(`${searchPage}?_physician_search=${escapedValue}`);
    } else {
        return;
    }
}