import './skip-link-focus-fix';
import AOS from 'aos';
import fancybox from '@fancyapps/fancybox';
import fontawesome from '@fortawesome/fontawesome';
import faSolid from '@fortawesome/fontawesome-free-solid';
import faRegular from '@fortawesome/fontawesome-free-regular';
import faBrands from '@fortawesome/fontawesome-free-brands';
import Cookies from 'js-cookie';
import 'script-loader!table2csv';
import { handleLocationSelect } from './handleLocationSelect';
import { handleNameSearch } from './handleNameSearch';
import { handleTabChange } from './handleTabChange';
import { handleSpecialTab } from './handleSpecialTab';

(function($) {




	$(document).ready(function() { 
		$('#therapist-location').on('change', function() {
			$('#therapist-finder').attr('action', +$(this).val());
		});
	});


	$('.procedures-toggle').on('click', function() {
		$('ul.physician-procedures li.toggler').toggleClass('toggled');
	});


	// wrap iframes in responsive container
	$(document).ready(function() {
		$('iframe:not(.full-video__video)', 'iframe:not(.reviews)').wrap('<div class="ffw-video"></div>');
	});

	// smooth scroll to anchor
	$(document).on('click', 'a[href^="#"]', function (event) {
		event.preventDefault();
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top-200
		}, 500);
	});

	// resource accordions
	$('.folder').on('click', function() {
		$(this).next('.folder-content').toggleClass('hidden');
		$('.folder').toggleClass('hidden');
	});

	$('.folder-back').on('click', function() {
		$(this).closest('.folder-content').toggleClass('hidden');
		$('.folder').toggleClass('hidden');
	});

	// fancybox
	$(document).ready(function() {
		$("a.fancybox").fancybox({
			'transitionIn'	:	'elastic',
			'transitionOut'	:	'elastic',
			'speedIn'		:	600, 
			'speedOut'		:	200, 
			'overlayShow'	:	false
		});
	});

	// physician finder tabs
	$('.physician-finder-button a').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$(this).parent().siblings().children('.tab-link.active').toggleClass('active');
		$('.tabs .tab').hide();
		$($(this).attr('id')).show();
	});

	// csv exports
	$(document).ready(function() {
		$('#derp-dl').on('click', function() {
			$("#derp").table2csv({
				filename: 'physicians'
			});
		});
	});

	AOS.init({ once: true, duration: 700 });

	$(document).ready(function() {
		menuToggle();
		searchToggle();
		educationToggle();
		accordionShortcode();
		appointmentRequest();
//		fullWidthVideo();
		accordionsBlock();
		interactiveImage();
		alertBanner();

		$('#primary a[href*=#]').on('click', function(e){
			e.preventDefault();

			$('html, body').animate({
				scrollTop: $( $.attr(this, 'href') ).offset().top
			}, 500);
		});
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-wrap").toggleClass('site-header__menu-wrap--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});

		$(".menu-item > a[href=#]").on('click', function(e) {
			e.preventDefault();
			var submenu = $(this).next('.sub-menu');
			$(".menu-item > a[href=#]").not($(this)).removeClass('open');
			$('.sub-menu').not(submenu).slideUp();
			$(this).toggleClass('open');
			submenu.slideToggle();
		});
	}

	// Header search toggle
	function searchToggle() {
		$('#search-toggle').on('click', function() {
			$('#header-search').toggleClass('site-header__search-form--open');
			$(this).siblings('.site-header__menu').toggleClass('site-header__menu--search-open');
			$('#search-field').focus();
			$('.ubermenu-primary_nav').toggleClass('search-open');
		});
	}

	// Physician affiliation and education toggle
	function educationToggle() {
		$('.education-toggle').on('click', function() {
			$(this).siblings('.education-toggled').slideToggle('fast');
			$(this).children().children('.flipper').toggleClass('rotated');
		});
		$('.bio-toggle').on('click', function() {
			$(this).siblings('.bio-toggled').slideToggle('fast');
			$(this).children().children('.flipper').toggleClass('rotated');
		});

		$('.physicians-toggle').on('click', function() {
			$(this).siblings('.physicians-toggled').slideToggle('fast');
			$(this).children().children('.flipper').toggleClass('rotated');
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Appointment request panel
	function appointmentRequest() {
		$('#appointmentRequestToggle, #appointmentRequestOverlay').on('click', function (e) {
			if ($(window).width() > 768) {
				e.preventDefault();
				$(this).parent('.appointment-request').toggleClass('appointment-request--open');
				$("#appointmentRequestOverlay").fadeToggle();
				$('body').toggleClass('no-scroll');
			}
		});
	}

	// Full width video
//	function fullWidthVideo() {
//		$('.full-video__overlay').on('click', function () {
//			var video = $(this).next('.full-video__video'),
//				src   = video.data('src');
//			video.attr('src', src);
//			video.show();
//		});
//	}

	// Accordions block
	function accordionsBlock() {
		$(".accordions-block__heading").on('click', function(e) {
			e.preventDefault();

			var accordion = $(this).closest('.accordions-block__accordion'),
				content   = accordion.find('.accordions-block__content');
				
			content.slideToggle(300);
			accordion.toggleClass('accordions-block__accordion--open');

			$('.accordions-block__content').not(content).slideUp(300);
			$('.accordions-block__accordion').not(accordion).removeClass('accordions-block__accordion--open');
		});
	}

	// Relateds Accordions
	$('.relateds-block__block--accordion-header').on('click', function() {
		$(this).siblings('.relateds-block__block--accordion-content').slideToggle('fast');
		$(this).children('.relateds-block__block--accordion-header-heading').children('.relateds-block__block--accordion-header-arrow').toggleClass('is-active');
		$(this).toggleClass('is-active');
	})

	// Interactive image block
	function interactiveImage() {
		$(".interactive-image__bullet").on('mouseover click', function(){
			var bullets   = $('.interactive-image__bullet'),
				pinpoints = $('.interactive-image__pinpoint'),
				id        = $(this).data('id');
				
			bullets.removeClass('interactive-image__bullet--active');
			$(this).addClass('interactive-image__bullet--active');

			pinpoints.removeClass('interactive-image__pinpoint--active');
			pinpoints.filter('[data-id="' + id + '"]').addClass('interactive-image__pinpoint--active');
		});

		$(".interactive-image__pinpoint").on('mouseover click', function(){
			var bullets   = $('.interactive-image__bullet'),
				pinpoints = $('.interactive-image__pinpoint'),
				id        = $(this).data('id');
								
			bullets.removeClass('interactive-image__bullet--active');
			bullets.filter('[data-id="' + id + '"]').addClass('interactive-image__bullet--active');

			pinpoints.removeClass('interactive-image__pinpoint--active');
			$(this).addClass('interactive-image__pinpoint--active');
		});

		$(".interactive-image__next").on('click', function(){
			var bullets       = $('.interactive-image__bullet'),
				currentBullet = bullets.filter('.interactive-image__bullet--active'),
				pinpoints     = $('.interactive-image__pinpoint'),
				id            = currentBullet.data('id'),
				count         = bullets.length,
				nextid        = id + 1;
				
			if( nextid > count ) {
				nextid = 1;
			}
								
			bullets.removeClass('interactive-image__bullet--active');
			bullets.filter('[data-id="' + nextid + '"]').addClass('interactive-image__bullet--active');

			pinpoints.removeClass('interactive-image__pinpoint--active');
			pinpoints.filter('[data-id="' + nextid + '"]').addClass('interactive-image__pinpoint--active');
		});

		$(".interactive-image__prev").on('click', function(){
			var bullets       = $('.interactive-image__bullet'),
				currentBullet = bullets.filter('.interactive-image__bullet--active'),
				pinpoints     = $('.interactive-image__pinpoint'),
				id            = currentBullet.data('id'),
				count         = bullets.length,
				nextid        = id - 1;
				
			if( nextid < 1 ) {
				nextid = count;
			}
								
			bullets.removeClass('interactive-image__bullet--active');
			bullets.filter('[data-id="' + nextid + '"]').addClass('interactive-image__bullet--active');

			pinpoints.removeClass('interactive-image__pinpoint--active');
			pinpoints.filter('[data-id="' + nextid + '"]').addClass('interactive-image__pinpoint--active');
		});
	}

	// Site alert banner
	function alertBanner() {
		var alertBanner = $("#alert-banner");
		if(alertBanner.length) {
			var cookie = Cookies.get('alert-shown');

			if ( !cookie || cookie != 1 ) {
				alertBanner.show();


				var headerHeight = alertBanner.height();
				$('#page').css('padding-top', headerHeight);
			}

			$("#alert-close").on('click', function(e) {
				e.preventDefault();
				alertBanner.hide();
				$('#page').css('padding-top', 0);	
				Cookies.set('alert-shown', 1, { expires: 7 } );
			});
		}
	}

	// New homepage hero accordion
	$('.nhh__accordion--toggle').on('click', function(e) {
		$(this).parent().toggleClass('is-active');
		$(this).siblings('.nhh__accordion--list').slideToggle('fast');
	});


	// Tabbed Accordion Content Location Select
	const locationSelect = document.querySelector('#locations-select');

	if (locationSelect) {
		locationSelect.addEventListener('change', (e) => handleLocationSelect(e, locationSelect));
	}

	// Tabbed Accordion Content Accordion
	$('.tac__tab--accordion-toggle').on('click', function(e) {
		$(this).parent().toggleClass('is-active');
		$(this).siblings('.tac__tab--accordion-list').slideToggle('fast');
	})

	// Tabbed Accordion Content Name Search
	const nameSearch = document.querySelector('#name-search');

	if (nameSearch) {
		nameSearch.addEventListener('submit', (e) => handleNameSearch(e, nameSearch));
	}

	// Tabbed Accordion Content Tab Change
	const tacContainer = document.querySelector('.tac__container');

	if (tacContainer) {
		handleTabChange(tacContainer);
	}

	// Special Tabs Innermost Accordions
	$('.stb__tab--accordion-accordion-toggle').on('click', function() {
		$(this).siblings('.stb__tab--accordion-accordion-content').slideToggle('fast');
		$(this).siblings('.stb__tab--accordion-accordion-circle').toggleClass('is-active');
	})

	// Special Tabs Outer Accordions
	$('.stb__tab--accordion-toggle').on('click', function() {
		$(this).siblings('.stb__tab--accordion-content').slideToggle('fast');
		$(this).find('.stb__tab--accordion-circle').toggleClass('is-active');
	})

	// Special Tab function
	const specialTabContainer = Array.from(document.querySelectorAll('.stb__container'));

	if (specialTabContainer.length > 0) {
		specialTabContainer.forEach(tabContainer => {
			handleSpecialTab(tabContainer);
		})
	}

})(jQuery);